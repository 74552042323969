<template>
  <main>
    <section class="horizon">
      <div v-if="showCounters" id="counters-data" class="fixed-container-counters" :style="bottomBarStyle">
        <div class="wrapper-counters show-counters">
          <small class="small-text">{{ data.length }} resultados de {{ total }} totales</small>
          <div class="controls-group">
            <div class="form-group">
              <label for="selectLimit" class="form-control__label">Limite</label>
              <select id="selectLimit" v-model="limit" class="form-control search-term_input" :value="limit">
                <option value="100">
                  100
                </option>
                <option value="200">
                  200
                </option>
                <option value="300">
                  300
                </option>
                <option value="500">
                  500
                </option>
                <option value="1000">
                  1000
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="skip" class="form-control__label">Salto</label>
              <input id="skip" v-model="skip" type="number" class="form-control search-term_input" name="skip">
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <Breadcrumb :list="breadcrumbList" />

        <div class="in-between">
          <h2 class="horizon__title">
            {{ title }}
          </h2>
        </div>

        <div class="row row-table">
          <article class="card card--full">
            <div class="card__body small-left">
              <div class="interact--container">
                <div class="form-control">
                  <label for="startdate" class="form-control__label">Desde</label>
                  <input id="startdate" v-model="startDate" type="date" name="startdate" class="search-term_input"
                    pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" placeholder="dd-mm-yyyy" autocomplete="on">
                </div>
                <div class="form-control">
                  <label for="endDate" class="form-control__label">Hasta</label>
                  <input id="endate" v-model="endDate" type="date" name="endate" class="search-term_input"
                    data-date-format="YYYY-MMMM-DD" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" autocomplete="on">
                </div>
                <button type="button" title="Filtar por fecha" name="filtro" class="date_filter"
                  @click="fetchData(true)">
                  Filtrar
                </button>
                <img class="icon-separator" src="~@/assets/img/iconos/separador.svg" alt="Icono separador">
                <button type="button" title="Descargar como excel" name="excel" class="date_filter"
                  @click="downloadExcel">
                  Descargar excel
                  <img v-if="!loadingExcel" src="~@/assets/img/iconos/excel-white.svg" alt="Icono de Excel">
                  <div v-if="loadingExcel" class="loading-excel" />
                </button>
                <div class="spacer" />
                <div class="interact--container">
                  <input id="searchTerm" v-model="searchText" type="text" name="search" placeholder="Búsqueda"
                    class="search-term_input">
                  <button type="button" title="Filtar por fecha" name="filtro" class="date_filter" @click="searchTerm">
                    Buscar
                  </button>
                </div>
              </div>
              <Separator />
              <small class="small-text view-counters">
                {{ data.length }} resultados de {{ total }} totales.
              </small>
              <div class="card__table">
                <!-- Table Templates -->
                <div v-if="!isComponent(templateTable)" class="template__warning">
                  <p>
                    <i>templateTable</i>
                    – El template indicado no se encuentra o no ha sido exportado.
                  </p>
                </div>
                <component :is="templateTable" :data="data" @:id-modal="openmodal" />
                <p v-if="loading" class="loading">
                  Cargando más resultados.
                </p>
              </div>
              <div class="pagination">
                <p />
              </div>
            </div>
          </article>
        </div>
      </div>
    </section>

    <section v-if="singleData" id="modal-section" class="section-modal">
      <div id="modal-container">
        <div class="modal-content">
          <div class="row">
            <article class="card card--full" style="padding-top: 0; padding-bottom: 0">
              <div class="card__body font-centered">
                <div style="text-align: right">
                  <span class="close" style="cursor: pointer; background-color: #f90; padding: 0.125rem 0.5rem"
                    @click="closemodal">
                    &times;
                  </span>
                </div>
                <h3 class="card__title title-small" style="margin-top: 0.5rem">
                  {{ title }} de {{ singleData['nombre'] }} {{ singleData['apellido_paterno'] }}
                </h3>
                <span style="font-size: 14px">Fecha: {{ singleData['fecha'] }}</span>

                <div class="card__table">
                  <div v-if="!isComponent(templateTableModal)" class="template__warning">
                    <p>
                      <i>templateTableModal</i>
                      – El template modal indicado no se encuentra o no ha sido exportado.
                    </p>
                  </div>

                  <!--  Table  Modal -->
                  <component :is="templateTableModal" :data="singleData" />
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
      <div id="overlay" @click="closemodal" />
    </section>
  </main>
</template>

<script>
/* eslint-disable vue/no-dupe-keys */

import { toRefs, ref, computed, onBeforeMount, onMounted, watch, provide, resolveDynamicComponent } from 'vue'
import { useRoute } from 'vue-router'
import axios from 'axios'
import moment from 'moment'
import FileSaver from 'file-saver'
//Components
import Separator from '@/components/Separator.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
// Template tables
import { FormsViews } from '@/components/templates/tables'

// Import xlsx para descarga de excel
import XLSX from 'xlsx'

export default {
  components: {
    Separator,
    ...FormsViews,
    Breadcrumb,
  },
  props: {
    title: String,
    collection: String,
    templateTable: String,
    templateTableModal: String,
    dataSource: String,
    apiSource: String,
    database: String,
    dataExcel: Object,
  },
  setup(props) {
    const route = useRoute()
    // Props
    const { collection, templateTable, templateTableModal, apiSource, dataExcel, title, dataSource } = toRefs(props)
    // States
    const data = ref([])
    const idModal = ref()
    const singleData = ref(false)
    const searchText = ref('')
    const skip = ref(0)
    const total = ref(0)
    const loading = ref(true)
    const loadingExcel = ref(false)
    const showCounters = ref(false)
    const startDate = ref(moment().startOf('week').format('YYYY-MM-DD'))
    const endDate = ref(moment().format('YYYY-MM-DD'))
    const limit = ref(100)
    const bottomCounter = ref(0)

    //Computed
    const targetDateField = computed(() => dataExcel.value.query.date_field)
    const formatDateField = computed(() => dataExcel.value.query.format_date_field)
    const startISODate = computed(() => moment(startDate.value + ' 00:00:00').format())
    const endISODate = computed(() => moment(endDate.value + ' 23:59:59').format())

    //formatDateField puede venir o puede no venir. Normalmente cuando viene es ISO y entonces se usa el formato ISO para las fechas
    //Si no viene, entonces se usa el formato yyyy-mm-dd 00:00:00
    //Eso es independiente de si el campo de fecha es createdAt o fecha

    const URLfetch = computed(() => {
      if (formatDateField.value == 'ISO') {
        return `${apiSource.value}${dataSource.value != undefined ? dataSource.value : ''}/read/${collection.value}/?start=${startISODate.value}&end=${endISODate.value}&skip=${skip.value}&limit=${limit.value}`
      }
      return `${apiSource.value}${dataSource.value != undefined ? dataSource.value : ''}/read/${collection.value}/?start=${startDate.value + ' 00:00:00'}&end=${endDate.value} 23:59:59&skip=${skip.value}&limit=${limit.value}`
    })

    const URLsearch = computed(
      () => `${apiSource.value}${dataSource.value != undefined ? dataSource.value : ''}/search/${collection.value}/?search=${searchText.value}`
    )

    // download por read
    // const URLexcel = computed(() => `${apiSource.value}${dataSource.value != undefined ? dataSource.value : ''}/download/${collection.value}`)
    const URLexcel = computed(() => `${apiSource.value}${dataSource.value != undefined ? dataSource.value : ''}/read/${collection.value}/?start=${startDate.value + ' 00:00:00'}&end=${endDate.value} 23:59:59&skip=0&limit=1000000`)
    const breadcrumbList = computed(() => route.meta.breadcrumb)
    const bottomBarStyle = computed(() => {
      return {
        'padding-bottom': `${bottomCounter.value}px`,
      }
    })

    // Methods
    const isComponent = (name) => typeof resolveDynamicComponent(name) !== 'string'

    const changeIdModal = (value) => {
      idModal.value = value
    }

    const downloadExcel = () => {
      loadingExcel.value = true

      dataExcel.value.query.fecha_inicio = startDate.value + ' 00:00:00'
      dataExcel.value.query.fecha_fin = endDate.value + ' 23:59:59'

      if (formatDateField.value == 'ISO') {
        dataExcel.value.query.fecha_inicio = startISODate.value
        dataExcel.value.query.fecha_fin = endISODate.value
      }

      axios
        .get(URLexcel.value, {
          headers: {
            'X-Field-Date': targetDateField.value ? targetDateField.value : 'fecha',
          },
        })
        .then((response) => {
          // Crear un array que contendrá los datos de la hoja de cálculo
          let ws_data = [];

          // Función recursiva para obtener todos los nombres de las columnas con sus claves y valores
          function getAllColumnNamesAndKeys(fields, parentKey = '') {
            let columnNames = [];

            for (const key in fields) {
              if (typeof fields[key] === 'string') {
                // Si el valor es un string, agregamos la clave y el valor a columnNames
                columnNames.push({ key: parentKey ? `${parentKey}.${key}` : key, value: fields[key] });
              } else if (typeof fields[key] === 'object') {
                // Si el valor es un objeto o un array, llamamos recursivamente a la función
                columnNames.push(...getAllColumnNamesAndKeys(fields[key], parentKey ? `${parentKey}.${key}` : key));
              }
            }

            return columnNames;
          }

          // Obtener todos los nombres de las columnas con sus claves y valores
          let columnNamesKeys = getAllColumnNamesAndKeys(dataExcel.value.fields);

          // Crear la primera fila con los nombres de las columnas
          let columnNames = columnNamesKeys.map(column => column.value);

          // Pushea la fila con los nombres de las columnas
          ws_data.push(columnNames);

          // Iterar sobre el response y contruye las filas con los valores de cada columna
          Object.entries(response.data.collection).forEach(subElement => {
            let [sKey, sValue] = subElement;
            let row = [];

            // Iterar sobre las columnas y obtener los valores correspondientes
            columnNamesKeys.forEach(column => {
              let cellValue = '';

              // Buscar la clave correspondiente en sValue y obtener su valor
              if (column.key.includes('.')) {
                const keys = column.key.split('.');
                let tempValue = sValue;
                for (const k of keys) {
                  tempValue = tempValue[k];
                  if (tempValue === undefined) break;
                }
                cellValue = tempValue !== undefined ? tempValue : '';
              } else {
                cellValue = sValue[column.key] !== undefined ? sValue[column.key] : '';
              }

              row.push(cellValue);
            });

            // Agregar la fila completa a ws_data
            ws_data.push(row);
          });

          // Crear un libro de trabajo y una hoja de cálculo con los datos
          let wb = XLSX.utils.book_new();
          let ws = XLSX.utils.aoa_to_sheet(ws_data);

          // Agregar la hoja de cálculo al libro de trabajo
          XLSX.utils.book_append_sheet(wb, ws, "Nombre de la Hoja");

          // Generar el archivo y simular el click para descargar
          XLSX.writeFile(wb, `${dataExcel.value.query.collection}-desde:${startDate.value + ' 00:00:00'}-hasta:${endDate.value + ' 23:59:59'}.xlsx`);

          // Marcar la carga del Excel como completa
          loadingExcel.value = false;
        })

        .catch((error) => {
          console.log(error)
        })
        .finally(() => (loadingExcel.value = false))
    }

    const fetchData = (resetValues) => {
      window.onscroll = () => {
        isCountersInViewport()
      }

      loading.value = true

      if (resetValues) {
        data.value = []
        skip.value = 0
        total.value = 0
      }



      axios
        .get(URLfetch.value, {
          headers: {
            'X-Field-Date': targetDateField.value ? targetDateField.value : 'fecha',
          },
        })
        .then((response) => {

          data.value = [...data.value, ...response.data.collection]
          skip.value = data.value.length
          total.value = response.data.count
          bottomCounter.value = 0
        })
        .catch((error) => {
          console.log(error)
        })
        .then(() => {
          loading.value = false
          window.onscroll = () => {
            handleScroll()
            isCountersInViewport()
          }
        })
    }

    const searchTerm = () => {
      if (searchText.value.length >= 3) {
        data.value = []
        loading.value = true
        axios
          .get(URLsearch.value)
          .then((response) => {
            data.value = response.data.collection
            skip.value = response.data.collection.length
            total.value = response.data.count
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => (loading.value = false))
      }
    }

    const handleScroll = () => {
      if (skip.value < total.value) {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight

        if (bottomOfWindow && skip.value > 0) {
          bottomCounter.value = 0
          fetchData(false)
        }
      }
    }

    const handleShowCounters = () => {
      showCounters.value = !showCounters.value
    }

    const isCountersInViewport = () => {
      const el = document.querySelector('.view-counters').getBoundingClientRect()
      const footer = document.querySelector('footer').getBoundingClientRect()
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

      if ((footer.top - vh) * -1 > 0) {
        bottomCounter.value = Math.abs(footer.top - vh)
      } else {
        bottomCounter.value = 0
      }

      let evaluatePosition =
        el.top >= 0 &&
        el.left >= 0 &&
        el.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        el.right <= (window.innerWidth || document.documentElement.clientWidth)

      if (showCounters.value == evaluatePosition) {
        handleShowCounters()
      }
    }

    const closemodal = () => {
      singleData.value = false
      idModal.value = undefined
    }

    const openmodal = () => {
      axios
        .get(apiSource.value + dataSource.value + '/readID/' + collection.value + '/' + idModal.value)
        .then((response) => {
          singleData.value = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    }

    //On Before Mount
    onBeforeMount(() => {
      fetchData(true)
    })

    // Mounted
    onMounted(() => {
      window.onscroll = () => {
        handleScroll()
        isCountersInViewport()
      }
    })

    // Watch
    watch(idModal, () => {
      if (idModal.value == undefined) return
      openmodal()
    })

    //Provide
    provide('updateIdModal', changeIdModal)

    return {
      route,
      title,
      bottomBarStyle,
      data,
      limit,
      skip,
      dataExcel,
      total,
      singleData,
      loading,
      loadingExcel,
      showCounters,
      startDate,
      endDate,
      breadcrumbList,
      templateTable,
      templateTableModal,
      fetchData,
      downloadExcel,
      handleShowCounters,
      isCountersInViewport,
      closemodal,
      openmodal,
      searchText,
      searchTerm,
      isComponent,
    }
  },
}
</script>

<style lang="scss" scoped>
.loading {
  padding: 0.5em;
  border-radius: 5px !important;
  background: url('~@/assets/img/iconos/loading.gif');
  background-size: 200px;
  background-position: center;
  background-repeat: no-repeat;
  text-indent: -10000px;
}

.loading-excel {
  width: var(--icon-size-small);
  height: var(--icon-size-small);
  margin-left: 10px;
  background: url('~@/assets/img/iconos/loading.gif');
  background-size: 120px;
  background-position: center;
  background-repeat: no-repeat;
}

.icon-separator {
  padding: 1rem;
}

.pagination {
  padding: 20px 0;
}

.date_filter {
  background: #a3b84b;
  padding: 8px 16px;
  border: 2px solid #9cb045;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-left: 10px;
    width: 22px;
  }

  &:hover {
    background-color: #72881a;
  }
}

.search-term_input {
  padding: 8px 8px;
  font-size: 1rem;
}

table {
  margin-top: 5px;
}

.small-text {
  text-align: left;
}

.card__body {
  padding: 1.5rem;
  border: 1px solid #cccccc;
  background: #f3f3f3;
}

.small-left {
  text-align: left;
}

small {
  font-weight: 500;
  font-size: 0.85rem;
  line-height: 19px;
  color: #333333;
}

.interact--container {
  align-items: flex-end;
}

.form-control:not(:last-child) {
  margin: 0px;
}

// Fixed Counter
.fixed-container-counters {
  position: fixed;
  left: 0;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
  z-index: 999;
  display: grid;
  place-items: center;
  padding-bottom: 0px;
}

.wrapper-counters {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  justify-items: center;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 2rem;
  color: white;
  background-color: #98989d;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  animation: showCounters 200ms forwards ease-in;
}

.form-group {
  text-align: left;
}

.controls-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.template__warning {
  background: rgb(255, 207, 179);
  border: 2px solid rgb(226, 53, 1);
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-weight: bold;
  margin-top: 1rem;
  color: rgb(54, 0, 0);

  p::selection {
    background-color: rgb(226, 53, 1);
    color: black;
  }
}

@keyframes showCounters {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
